import { bootstrapApplication } from '@angular/platform-browser';
import hljs from 'highlight.js';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));

(window as any).hljs = hljs; // Make highlight.js globally available for Quill


let pathname = window.location.pathname;
let notRemoveData = pathname === "/create-event" || pathname === "/event-details" || pathname === "/create-event-details";

if (!notRemoveData) {
  localStorage.removeItem('StoredData');
}
