<ng-modals
  #announcementPopup
  id="announcementPopup"
  [placement]="'modal-center'"
  className="overflow-auto fixed top-0 left-0 w-full h-full z-drawer flex items-center justify-center bg-black bg-opacity-75">
  <div
    #container
    class="w-[calc(100vw-1rem)] md:max-w-[90%] sm:w-[60rem] bg-white shadow dark:bg-zink-600 flex flex-col relative announcement_main_container">
    <div class="grid grid-cols-12 h-full">
      <div class="col-span-12 md:col-span-5 announcement_left_container h-full px-5 md:px-10 relative">
        <div class="flex-1 flex flex-col justify-center overflow-auto">
          <!-- multi announcement -->
          <div
            class="flex items-center justify-between py-3 border-b border-slate-200 dark:border-zink-500">
            <div class="text-[16px] text-black dark:text-white font-bold">What’s New</div>
          </div>
          <div class="mt-4 mb-[3rem]">
            <ngx-slick-carousel
              #slickModal="slick-carousel"
              class="swiper navigation-swiper custom-arrow"
              [config]="carouselShowOpt"
              (afterChange)="handleChangeAnnouncement($event)">
              <div *ngFor="let item of announcements">
                <div ngxSlickItem>
                  <div class="">
                    <div class="rounded-t-md">
                      <span class="text-[14px] text-black dark:text-white font-bold">
                        {{ item?.announcement?.title ?? '' }}
                      </span>
                      <!-- <span *ngIf="item?.announcement?.title?.length > 150" 
                        class="text-blue-500 cursor-pointer" 
                        (click)="viewMoreContent(item?.announcement?.title ?? '')"
                        >
                        view more
                      </span> -->
                    </div>
                    <div class="mt-3" *ngIf="item?.announcement?.desc">
                      <div
                        [innerHTML]="item?.announcement?.desc ?? ''"
                        class="text-black dark:text-white text-[12px] quill-content">
                      </div>
                      <!-- <span *ngIf="item?.announcement?.desc?.length > 150" 
                        class="text-blue-500 cursor-pointer" 
                        (click)="viewMoreContent(item?.announcement?.desc ?? '')"
                        >
                        view more
                      </span> -->
                    </div>
                    <div
                      class="text-center mt-10"
                      *ngIf="item?.announcement?.redirectionScreen">
                      <button
                        (click)="showRedirectScreen(true)"
                        class="title-btn w-full h-10 text-white btn bg-custom-500 hover:text-white hover:bg-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20 rounded-xl">
                        {{ item?.announcement?.buttonText ? item?.announcement?.buttonText : 'Show' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
        <div class="flex items-center justify-center absolute left-0 right-0 bottom-[1rem]" *ngIf="announcements?.length > 1">
          <div *ngFor="let item of announcements; let index = index">
            <div
              *ngIf="index <= 3"
              class="cursor-pointer w-[25px] h-[7px] rounded-full mx-1"
              [ngClass]="
                currSlideData?.id === item?.id ? 'bg-[#3B82F6]' : 'bg-[#249782]'
              "
              (click)="goToSlide(index)"></div>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-7 announcement_right_container dark:bg-[#132337] bg-[#fff] h-full px-5 md:px-10">
        <div
          class="flex items-center justify-between px-0 py-3 border-b border-slate-200 dark:border-zink-500">
          <div class="text-[16px] text-black dark:text-white font-bold">Feature Header</div>
        </div>
        <div *ngIf="currSlideContent">
          <div *ngIf="isVideo">
            <div *ngIf="!isYoutubeVideo" class="mt-4">
              <video
                controls
                [autoplay]="false"
                [loop]="false"
                [muted]="false"
                [poster]="currSlideContent?.thumbnailImage ?? ''"
                class="h-[12rem]">
                <source [src]="currSlideContent?.contentUrl" type="video/mp4" />
              </video>
            </div>
            <div *ngIf="isYoutubeVideo">
              <div class="mt-4">
                <youtube-player
                  [videoId]="getYouTubeVideoId(currSlideContent?.contentUrl)"
                  placeholderImageQuality="high"
                  height="200"
                  loadApi="true" />
              </div>
            </div>
          </div>
          <div *ngIf="!isVideo" class="w-full h-[12rem] relative mt-4">
            <img
              [src]="currSlideContent?.contentUrl"
              class="object-cover w-full h-full" />
          </div>
        </div>
        <div class="md:mb-[6rem]">
          <div class="text-black dark:text-white text-[12px] mt-4">
            <!-- {{renderTitle(currSlideData?.announcement?.body ?? '', 150)}} -->
            <div
              [innerHTML]="currSlideData?.announcement?.body ?? ''"
              class="text-black dark:text-white text-[12px] quill-content">
          </div>
            <!-- <span *ngIf="currSlideData?.announcement?.body?.length > 150" 
              class="text-blue-500 cursor-pointer" 
              (click)="viewMoreContent(currSlideData?.announcement?.body)"
              >
              view more
            </span> -->
          </div>
        </div>
        <!-- <div class="w-full">
          <hr class="dark:border-[#1D334E] border-[#e2e8f0] border-[1.5px]" />
        </div> -->
        <div
          (click)="showRedirectScreen(false)"
          class="md:absolute border-t border-slate-200 dark:border-zink-500 text-center md:text-end mb-5 md:mb-0 left-[1.25rem] right-[1.25rem] md:left-[2.5rem] md:right-[2.5rem] bottom-[1rem] pt-4 mt-4">
          <p class="text-[#E05D43] text-[10px] underline cursor-pointer">
            Don’t show me this again
          </p>
        </div>
      </div>
      <button
        dismissModal
        (click)="closeAnnouncements()"
        [ModalDismiss]="'announcementPopup'"
        class="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500 absolute top-4 right-4">
        <lucide-angular name="x" [class]="'size-5'"></lucide-angular>
      </button>
    </div>
    <div>
      <div
        *ngIf="currSlideIndex !== 0"
        [ngClass]="isDarkMode ? 'slide_arrows_dark' : 'slide_arrows_light'"
        class="slide_arrows slide_arrow_left"
        (click)="slideNextPrev('PREV')">
        <i class="ri-arrow-left-s-fill text-2xl text-white"></i>
      </div>
      <div
        *ngIf="currSlideIndex !== (announcements?.length - 1)"
        [ngClass]="isDarkMode ? 'slide_arrows_dark' : 'slide_arrows_light'"
        class="slide_arrows slide_arrow_right"
        (click)="slideNextPrev('NEXT')">
        <i class="ri-arrow-right-s-fill text-2xl text-white"></i>
      </div>
    </div>
  </div>
  <app-view-product-description #modal [isRenderHTML]="true" title="View More"></app-view-product-description> 
</ng-modals>
