import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { API_ANNOUNCEMENT } from '../helpers/api_constant';
import { handleError } from '../helpers/utils';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  constructor(
    public http: HttpClient,
  ) { }

  getAnnouncementList(): Observable<any> {
    return this.http.get<any>(`${environment.API}${API_ANNOUNCEMENT.ANNOUNCEMENT_ACTION}`).pipe(catchError(handleError))
  }

  makeAnnouncementAction(payload: any): Observable<any> {
    return this.http.put<any>(`${environment.API}${API_ANNOUNCEMENT.ANNOUNCEMENT_ACTION}/${payload?.id}`, { isSuccess: payload?.isSuccess }).pipe(catchError(handleError))
  }

  closeAllAnnouncements(payload: any): Observable<any> {
    return this.http.put<any>(`${environment.API}${API_ANNOUNCEMENT.ANNOUNCEMENT_ACTION}`, payload).pipe(catchError(handleError))
  }
}
