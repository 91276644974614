import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { YouTubePlayer } from '@angular/youtube-player';
import { charValidate } from '@app/core/helpers/utils';
import { PipesModule } from '@app/core/pipes/pipes.module';
import { AnnouncementService } from '@app/core/services/announcement.service';
import { ViewProductDescriptionComponent } from '@app/pages/purchase-product/view-product-description/view-product-description.component';
import { MDModalModule } from '@app/shared/component/modals';
import { getLayoutmode } from '@app/store/layout/layout-selector';
import { Store } from '@ngrx/store';
import { LucideAngularModule } from 'lucide-angular';
import { SlickCarouselComponent, SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerService } from 'ngx-spinner';

enum ESlideNextPrev {
  NEXT = 'NEXT',
  PREV = 'PREV'
}
@Component({
  selector: 'app-announcement-popup',
  standalone: true,
  imports: [
    MDModalModule,
    PipesModule,
    SlickCarouselModule,
    LucideAngularModule,
    CommonModule,
    YouTubePlayer,
    ViewProductDescriptionComponent
  ],
  templateUrl: './announcement-popup.component.html',
  styleUrl: './announcement-popup.component.css'
})
export class AnnouncementPopupComponent {
  @Output() closeModal = new EventEmitter<any>();
  @Output() getAnnouncements = new EventEmitter<any>();
  carouselShowOpt = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    initialSlide: 0
  };
  isDarkMode: boolean = false;
  isDontShow: boolean = false;
  currSlideData: any = null;
  currSlideContent: any = null;
  isYoutubeVideo: boolean = false;
  isVideo: boolean = false;
  currSlideIndex: number = 0;

  @Input() announcements: any = [];
  @Input() announcementPopup!: any;
  private store = inject(Store);
  @ViewChild('slickModal') slickModal!: SlickCarouselComponent;
  @ViewChild(ViewProductDescriptionComponent) modal!: ViewProductDescriptionComponent;
  @ViewChild('container') container!: ElementRef;

  constructor(
    private announcementService: AnnouncementService,
    private ngxSpinnerService: NgxSpinnerService,
    private router: Router,
    private el: ElementRef
  ) {
    this.store.select(getLayoutmode).subscribe((mode) => {
      this.isDarkMode = mode === 'dark' ? true : false;
    });
  }

  ngOnChanges() {
    if (this.announcements?.length) {
      this.currSlideData = this.announcements[0];
      if (this.currSlideData?.announcement?.content) {
        this.currSlideContent = this.currSlideData?.announcement?.content;
        if (this.currSlideContent?.type === 'video') {
          this.isVideo = true;
        } else {
          this.isVideo = false;
        }
        if (this.currSlideContent?.contentUrl?.includes('youtube.com') || this.currSlideContent?.contentUrl?.includes('youtu.be')) {
          this.isYoutubeVideo = true;
        } else {
          this.isYoutubeVideo = false;
        }
      } else {
        this.currSlideContent = null;
      }
    } else {
      this.closeModal.emit();
    }
  }

  ngAfterViewInit() {
    this.setHTMLContentFontSize();
  }

  closePopup() {
    // this.carouselShowOpt = { ...this.carouselShowOpt, initialSlide: 0 };
    this.showRedirectScreen(true)
  }

  handleDoNotShowMeAgain(event: any, id: number) {
    this.isDontShow = event?.target?.checked;
  }

  showRedirectScreen(isSuccess: boolean) {
    let payload = {
      isSuccess: isSuccess,
      id: this.currSlideData?.id
    }
    this.ngxSpinnerService.show()
    this.announcementService.makeAnnouncementAction(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide()
      if (response?.status) {
        if (isSuccess) {
          this.closeModal.emit();
          this.router.navigate(['/settings']);
        } else {
          this.getAnnouncements.emit();
        }
      }
    }, () => {
      this.ngxSpinnerService.hide()
    });
  }

  renderTitle(title: string, length: number): string {
    if (title) {
      //commenting for future code change
      // return charValidate(title, title?.includes('<h2>') || title?.includes('<h1>') || title?.includes('<h3>') ? 70 : length);
      return charValidate(title, length);
    } else {
      return ''
    }
  }

  handleChangeAnnouncement(data: any) {
    if (typeof data?.currentSlide !== 'undefined' && typeof data?.currentSlide !== null) {
      this.currSlideIndex = data?.currentSlide;
      this.currSlideData = this.announcements[data?.currentSlide];
      if (this.currSlideData?.announcement?.content) {
        this.currSlideContent = this.currSlideData?.announcement?.content;
        if (this.currSlideContent?.type === 'video') {
          this.isVideo = true;
        } else {
          this.isVideo = false;
        }
        if (this.currSlideContent?.contentUrl?.includes('youtube.com') || this.currSlideContent?.contentUrl?.includes('youtu.be')) {
          this.isYoutubeVideo = true;
        } else {
          this.isYoutubeVideo = false;
        }
      } else {
        this.currSlideContent = null;
      }
      this.setHTMLContentFontSize();
    }
  }

  setHTMLContentFontSize() {
    // Select elements only inside the container
    const containerElement = this.container.nativeElement;
    // Change font size h1,h2,h3,h4,h5 to 12px
    let h1 = containerElement.querySelectorAll('h1')
    let h2 = containerElement.querySelectorAll('h2')
    let h3 = containerElement.querySelectorAll('h3')
    let h4 = containerElement.querySelectorAll('h4')
    let h5 = containerElement.querySelectorAll('h5')
    let h6 = containerElement.querySelectorAll('h6')
    let p = containerElement.querySelectorAll('p')
    if (h1 && h1?.length) {
      h1?.forEach((h1: HTMLElement) => {
        h1.style.fontSize = '12px';
      });
    }
    if (h2 && h2?.length) {
      h2?.forEach((h2: HTMLElement) => {
        h2.style.fontSize = '12px';
      });
    }
    if (h3 && h3?.length) {
      h3?.forEach((h3: HTMLElement) => {
        h3.style.fontSize = '12px';
      });
    }
    if (h4 && h4?.length) {
      h4?.forEach((h4: HTMLElement) => {
        h4.style.fontSize = '12px';
      });
    }
    if (h5 && h5?.length) {
      h5?.forEach((h5: HTMLElement) => {
        h5.style.fontSize = '12px';
      });
    }
    if (h6 && h6?.length) {
      h6?.forEach((h6: HTMLElement) => {
        h6.style.fontSize = '12px';
      });
    }
    if (p && p?.length) {
      p?.forEach((p: HTMLElement) => {
        p.style.fontSize = '12px';
      });
    }
  }

  getYouTubeVideoId(url: string): string {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : '';
  }

  goToSlide(index: number) {
    this.currSlideIndex = index;
    this.slickModal.slickGoTo(index);
  }

  slideNextPrev(type: string) {
    if (type === ESlideNextPrev.PREV) {
      this.currSlideIndex--;
      this.slickModal.slickGoTo(this.currSlideIndex);
    }
    if (type === ESlideNextPrev.NEXT) {
      this.currSlideIndex++;
      this.slickModal.slickGoTo(this.currSlideIndex);
    }
  }

  viewMoreContent(desc: string) {
    this.modal.openModal(desc);
  }

  closeAnnouncements() {
    let payload = {
      isSuccess: false,
      ids: this.announcements?.map((item: any) => item?.id)
    }
    this.ngxSpinnerService.show()
    this.announcementService.closeAllAnnouncements(payload).subscribe((response: any) => {
      this.ngxSpinnerService.hide()
      if (response?.status) {
        this.closeModal.emit();
      }
    }, () => {
      this.ngxSpinnerService.hide()
    });
  }
}
