<header
  id="page-topbar"
  (window:scroll)="windowScroll()"
  class="ltr:md:left-vertical-menu rtl:md:right-vertical-menu group-data-[sidebar-size=md]:ltr:md:left-vertical-menu-md group-data-[sidebar-size=md]:rtl:md:right-vertical-menu-md group-data-[sidebar-size=sm]:ltr:md:left-vertical-menu-sm group-data-[sidebar-size=sm]:rtl:md:right-vertical-menu-sm group-data-[layout=horizontal]:ltr:left-0 group-data-[layout=horizontal]:rtl:right-0 fixed right-0 z-[1000] left-0 print:hidden group-data-[navbar=bordered]:m-4 group-data-[navbar=bordered]:[&.is-sticky]:mt-0 transition-all ease-linear duration-300 group-data-[navbar=hidden]:hidden group-data-[navbar=scroll]:absolute group/topbar group-data-[layout=horizontal]:z-[1004]">
  <div class="layout-width">
    <div
      class="flex items-center px-4 mx-auto bg-topbar border-b-2 border-topbar group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand shadow-md shadow-slate-200/50 group-data-[navbar=bordered]:rounded-md group-data-[navbar=bordered]:group-[.is-sticky]/topbar:rounded-t-none group-data-[topbar=dark]:dark:bg-[#132337] group-data-[topbar=dark]:dark:border-zink-700 dark:shadow-none group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-zink-500 group-data-[topbar=dark]:group-[.is-sticky]/topbar:dark:shadow-md group-data-[navbar=bordered]:shadow-none group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none group-data-[layout=horizontal]:shadow-none group-data-[layout=horizontal]:dark:group-[.is-sticky]/topbar:shadow-none">
      <div
        class="flex items-center w-full group-data-[layout=horizontal]:mx-auto group-data-[layout=horizontal]:max-w-screen-2xl navbar-header group-data-[layout=horizontal]:ltr:xl:pr-3 group-data-[layout=horizontal]:rtl:xl:pl-3">
        <!-- LOGO -->
        <div
          class="items-center justify-center hidden px-5 text-center group-data-[layout=horizontal]:md:flex group-data-[layout=horizontal]:ltr::pl-0 group-data-[layout=horizontal]:rtl:pr-0">
          <a routerLink="/">
            <span class="hidden">
              <img src="assets/images/logo-sm.png" alt="" class="h-6 mx-auto" />
            </span>
            <span
              class="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
              <img
                src="assets/images/logo-dark.png"
                alt=""
                class="h-6 mx-auto" />
            </span>
          </a>
          <a
            routerLink="/"
            class="hidden group-data-[topbar=dark]:block group-data-[topbar=brand]:block">
            <span
              class="group-data-[topbar=dark]:hidden group-data-[topbar=brand]:hidden">
              <img src="assets/images/logo-sm.png" alt="" class="h-6 mx-auto" />
            </span>
            <span
              class="group-data-[topbar=dark]:block group-data-[topbar=brand]:block">
              <img
                src="assets/images/logo-light.png"
                alt=""
                class="h-6 mx-auto" />
            </span>
          </a>
        </div>

        <button
          type="button"
          (click)="changeSidebar()"
          class="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear bg-topbar rounded-md btn hover:bg-slate-100 group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:border-topbar-dark group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:border-topbar-brand group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:dark:border-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[layout=horizontal]:flex group-data-[layout=horizontal]:md:hidden hamburger-icon"
          id="topnav-hamburger-icon">
          <lucide-angular
            name="chevrons-left"
            [class]="
              'w-5 h-5 group-data-[sidebar-size=sm]:hidden'
            "></lucide-angular>
          <lucide-angular
            name="chevrons-right"
            [class]="
              'hidden w-5 h-5 group-data-[sidebar-size=sm]:block'
            "></lucide-angular>
        </button>
        <div class="flex gap-3 ms-auto">
          <div class="relative flex items-center dropdown mt-[2px]">
            <button
              type="button"
              (click)="showUserNotification()"
              class="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark"
              id="light-dark-mode">
              <div class="relative">
                <i class="ri-notification-3-fill text-[18px]"></i>
                <p
                  *ngIf="isShowNotificationDot"
                  class="absolute h-[10px] w-[10px] right-[-2px] top-[-2px] rounded-full bg-[#249782]"></p>
              </div>
            </button>
          </div>
          <div class="relative flex items-center dropdown">
            <button
              type="button"
              (click)="changeLayoutMode()"
              class="mr-1 inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-200 ease-linear bg-topbar rounded-md btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200 group-data-[topbar=dark]:text-topbar-item-dark"
              id="light-dark-mode">
              <lucide-angular
                [name]="isDarkMode ? 'moon' : 'sun'"
                [class]="
                  'inline-block size-5 stroke-2 fill-slate-100 group-data-[topbar=dark]:fill-topbar-item-bg-hover-dark group-data-[topbar=brand]:fill-topbar-item-bg-hover-brand'
                "></lucide-angular>
            </button>
          </div>

          <div
            mndropdown
            [placement]="'bottom-start'"
            class="relative flex items-center dropdown">
            <button
              mndropdownToggle
              [DrawerTrigger]="'userProfile'"
              type="button"
              class="inline-block p-0 transition-all duration-200 ease-linear bg-topbar rounded-full text-topbar-item dropdown-toggle btn hover:bg-topbar-item-bg-hover hover:text-topbar-item-hover group-data-[topbar=dark]:text-topbar-item-dark group-data-[topbar=dark]:bg-topbar-dark group-data-[topbar=dark]:hover:bg-topbar-item-bg-hover-dark group-data-[topbar=dark]:hover:text-topbar-item-hover-dark group-data-[topbar=brand]:bg-topbar-brand group-data-[topbar=brand]:hover:bg-topbar-item-bg-hover-brand group-data-[topbar=brand]:hover:text-topbar-item-hover-brand group-data-[topbar=dark]:dark:bg-zink-700 group-data-[topbar=dark]:dark:hover:bg-zink-600 group-data-[topbar=brand]:text-topbar-item-brand group-data-[topbar=dark]:dark:hover:text-zink-50 group-data-[topbar=dark]:dark:text-zink-200"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown">
              <div class="bg-pink-100 rounded-full">
                <img
                  *ngIf="userDetails?.profilePictureUrl"
                  [src]="
                    userDetails?.profilePictureUrl ??
                    'assets/images/users/user-dummy-img.jpg'
                  "
                  alt=""
                  class="size-[37.5px] rounded-full" />
              </div>
              <div
                *ngIf="!userDetails?.profilePictureUrl"
                class="max-w-9 max-h-9 w-9 h-9 object-cover rounded-full bg-black flex items-center justify-center">
                <span class="text-white text-sm font-bold">
                  {{ userDetails?.firstName?.charAt(0) | uppercase
                  }}{{ userDetails?.lastName?.charAt(0) | uppercase }}
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<drawer id="userProfile" [position]="'drawer-end'">
  <div
    mndropdownMenu
    class="scrollBarhide relative !h-[calc(100vh_-_100px)] max-h-[650px] min-w-[24rem] sm:min-w-[32rem] z-50 ltr:text-left right-0 rtl:text-right shadow-md dropdown-menu bg-[#EBF0F6] dark:bg-[#101824] dark:border-zink-600 overflow-y-scroll pb-10 sm:pb-0"
    aria-labelledby="dropdownMenuButton">
    <div
      class="flex fixed top-0 right-0 bg-[#ebf0f6] dark:bg-[#101824] w-full justify-end py-2 px-5 z-50">
      <button
        dismissDrawer
        [DrawerDismiss]="'userProfile'"
        class="transition-all duration-150 ease-linear dark:text-white hover:!text-[#ef4444]">
        <lucide-angular name="x" [class]="'size-5'"></lucide-angular>
      </button>
    </div>
    <div class="p-5 !pt-2 md:p-8 mt-8">
      <div
        class="border-b-2 border-solid border-[#E2E8F0] dark:border-[#1D334E] mb-7">
        <div
          class="flex gap-3 mb-5 bg-[#F3F6F9] dark:bg-[#132337] text-black p-4 rounded-lg items-center">
          <div class="relative inline-block shrink-0">
            <div>
              <img
                *ngIf="userDetails?.profilePictureUrl"
                [src]="
                  userDetails?.profilePictureUrl
                    ? userDetails?.profilePictureUrl
                    : 'assets/images/users/user-dummy-img.jpg'
                "
                alt=""
                class="rounded-full size-24 object-cover" />
            </div>
            <div
              *ngIf="!userDetails?.profilePictureUrl"
              class="rounded-full size-24 object-cover rounded-full bg-black flex items-center justify-center">
              <span class="text-white text-[26px] font-bold">
                {{ userDetails?.firstName?.charAt(0) | uppercase
                }}{{ userDetails?.lastName?.charAt(0) | uppercase }}
              </span>
            </div>
          </div>
          <div class="text-black dark:text-white w-full">
            <div class="mb-3">
              <h6 class="mb-1 text-15 text-black font-extrabold">
                {{ userDetails?.firstName | titlecase }}
                {{ userDetails?.lastName | titlecase }}
              </h6>
              <!-- <p class="text-15">
                    {{ userDetails.roles[0] }}
                  </p> -->
            </div>
            <div class="flex justify-between flex-col sm:flex-row">
              <div
                class="hidden sm:block text-center border-r border-dashed border-[#E2E8F0] dark:border-[#617188] py-2 pr-4">
                <p class="text-xs">{{ userDetails?.weight ?? '-' }}</p>
                <p class="text-[#64748B] text-[10px]">Weight</p>
              </div>
              <div
                class="hidden sm:block text-center border-r border-dashed border-[#E2E8F0] dark:border-[#617188] py-2 px-4">
                <p class="text-xs">
                  {{
                    (userDetails?.heightFeet
                      ? userDetails?.heightFeet + '.'
                      : '') + (userDetails?.heightInch ?? '-')
                  }}
                </p>
                <p class="text-[#64748B] text-[10px]">Height</p>
              </div>
              <div class="flex sm:hidden text-center border-b border-dashed border-[#E2E8F0] dark:border-[#617188] py-2 px-4 justify-center">
                <p
                  class="text-15 m-1 text-[#64748B] font-bold h-7 w-7 rounded-full bg-[#101824] flex justify-center items-center">
                  F
                </p>
                <p
                  class="text-15 m-1 text-[#64748B] font-bold h-7 w-7 rounded-full bg-[#101824] flex justify-center items-center">
                  B
                </p>
              </div>
              <div class="flex w-full">
                <div
                  class="text-center border-r border-dashed border-[#E2E8F0] dark:border-[#617188] py-2 px-4 w-full">
                  <p class="text-xs">
                    {{ userDetails?.dob ? formatDob(userDetails.dob) : '-' }}
                  </p>
                  <p class="text-[#64748B] text-[10px]">DOB</p>
                </div>
                <div class="text-center py-2 px-4 w-full">
                  <p class="text-xs">{{ userDetails?.grade?.name ?? '-' }}</p>
                  <p class="text-[#64748B] text-[10px]">Grade</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul
        class="border-b-2 border-solid border-[#E2E8F0] dark:border-[#1D334E] pb-7 mb-5">
        <li>
          <a
            dismissDrawer
            [DrawerDismiss]="'userProfile'"
            class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-[#64748B] dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-white dark:hover:text-custom-500 dark:focus:text-custom-500"
            routerLink="/change-password">
            <i class="ri-rotate-lock-line text-lg mr-2"></i>Change Password</a
          >
        </li>
        <li>
          <a
            dismissDrawer
            [DrawerDismiss]="'userProfile'"
            class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-[#64748B] dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-white dark:hover:text-custom-500 dark:focus:text-custom-500"
            routerLink="/profile">
            <i class="ri-user-3-line text-lg mr-2"></i>Profile</a
          >
        </li>
        <li>
          <a
            dismissDrawer
            [DrawerDismiss]="'userProfile'"
            class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-[#64748B] dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-white dark:hover:text-custom-500 dark:focus:text-custom-500"
            routerLink="/settings">
            <i class="ri-settings-5-line text-lg mr-2"></i>Settings</a
          >
        </li>
        <li *ngIf="isAdmin">
          <a
            dismissDrawer
            [DrawerDismiss]="'userProfile'"
            class="block ltr:pr-4 rtl:pl-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-[#64748B] dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-white dark:hover:text-custom-500 dark:focus:text-custom-500"
            routerLink="/admin-center">
            <i class="ri-admin-line text-lg mr-2"></i>Admin Center</a
          >
        </li>
      </ul>
      <div class="flex gap-3 text-center justify-center mb-7">
        <div
          class="min-w-[120px] w-full cursor-pointer bg-[#F3F6F9] dark:bg-[#132337] dark:text-white px-4 py-8 rounded-lg text-[#64748B] hover:text-[#000000] hover:font-bold">
          <i class="ri-user-heart-line text-xl"></i>
          <p class="text-[12px] font-normal mt-2">Volunteer for Round Tables</p>
        </div>
        <div
          dismissDrawer
          [DrawerDismiss]="'userProfile'"
          (click)="goToHowToVideosPage()"
          class="min-w-[120px] w-full cursor-pointer bg-[#F3F6F9] dark:bg-[#132337] dark:text-white px-4 py-8 rounded-lg text-[#64748B] hover:text-[#000000] hover:font-bold">
          <i class="ri-question-line text-xl mt-2"></i>
          <p class="text-[12px] font-normal">How-To Videos</p>
        </div>

        <div
          dismissDrawer
          [DrawerDismiss]="'userProfile'"
          (click)="goToContactPage()"
          class="min-w-[120px] w-full bg-[#F3F6F9] dark:bg-[#132337] dark:text-white px-4 py-8 rounded-lg text-[#64748B] hover:text-[#000000] hover:font-bold cursor-pointer">
          <i class="ri-customer-service-2-fill text-xl mt-2"></i>
          <p class="text-[12px] font-normal">Contact Support</p>
        </div>
      </div>
      <div class="text-center">
        <button
          class="w-5/6 text-[#C9D8E9] text-base transition-all duration-200 ease-linear btn bg-black border-black hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:bg-custom-600"
          (click)="signOut()">
          Logout
        </button>
      </div>
    </div>
  </div>
</drawer>
