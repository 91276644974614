import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { showAlertMessage } from '@app/core/helpers/utils';
import { AuthenticationService } from '@app/core/services/auth.service';
interface Step1Detail {
  password: string;
  confirmPassword: number | string;
}

@Component({
  selector: 'app-sign-up-step1',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './sign-up-step1.component.html',
  styleUrls: ['./sign-up-step1.component.css']
})
export class SignUpStep1Component implements OnInit {

  @Output() stepValue = new EventEmitter<any>();
  @Input() userDetail: any
  hidePasswordField: boolean = true;
  hideConfirmField: boolean = true;
  passwordForm: FormGroup = new FormGroup({});
  step1Details?: Step1Detail;
  passwords: any;
  submitted: boolean = false;
  // @Output() otpNumber = new EventEmitter<any>();
  detailForOtp: any
  isFormSubmitted = false;
  constructor(private fb: FormBuilder, private authService: AuthenticationService) { }

  passwordStrength = 0;

  passwordRules = [
    { message: 'Password must be 8 characters', valid: false, test: (value: string) => value.length >= 8 },
    { message: 'Password must contain 1 uppercase and 1 lowercase letters', valid: false, test: (value: string) => /[A-Z]/.test(value) && /[a-z]/.test(value) },
    { message: 'Password must contain at least 1 number', valid: false, test: (value: string) => /\d/.test(value) },
    { message: 'Password must contain at least 1 symbol', valid: false, test: (value: string) => /[!@#$%^&*]/.test(value) },
  ];

  ngOnInit() {
    this.initForm();
    this.passwordForm.get('password')?.valueChanges.subscribe(value => {
      this.updatePasswordRules(value);
    });
  }

  updatePasswordRules(value: string): void {
    let validRulesCount = 0;

    this.passwordRules.forEach(rule => {
      rule.valid = rule.test(value);
      if (rule.valid) validRulesCount++;
    });

    this.passwordStrength = (validRulesCount / this.passwordRules.length) * 100;
  }
  initForm() {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    this.passwordForm = this.fb.group({
      password: [this.step1Details?.password, [Validators.required, Validators.pattern(passwordPattern)]],
      confirmPassword: [this.step1Details?.confirmPassword, [Validators.required]]
    }, { validator: this.ConfirmPasswordValidator('password', 'confirmPassword') });
  }

  emitStep(step: number) {
    this.isFormSubmitted = true;
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }
    this.submitted = true;
    localStorage.setItem('password', this.passwordForm.get('password')?.value)
    const passwordValue = this.passwordForm.get('password')?.value;
    let mergedDetails = {
      ...this.userDetail,
      password: passwordValue,
      countryCode: "+1",
      dialCode: "US",
    };

    // this.otpNumber.emit(mergedDetails);
    this.onRegister(mergedDetails, step);
  }

  onRegister(userData: any, step: any) {
    userData['accessCode'] = undefined;
    userData['role'] = undefined;
    this.authService.registerData(userData).subscribe(
      (response: any) => {
        if (response.status == 1) {
          showAlertMessage(response?.message, "success", () => {
            this.submitted = false
            this.stepValue.emit(step);
          })
        }
      }, (response) => {
        this.submitted = false;
        showAlertMessage(response, "error")
      })
  }

  ConfirmPasswordValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors?.['confirmPasswordValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmPasswordValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  togglePasswordVisibility() {
    this.hidePasswordField = !this.hidePasswordField;
  }
  toggleConfirmVisibility() {
    this.hideConfirmField = !this.hideConfirmField;
  }

}